import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../Components/flights/FlightSearchForm.css'
import arrivalsvg from './assets/svg/arrival.svg'
import departuresvg from './assets/svg/departure.svg'
import DatePickerIcon from './assets/svg/Datepicker.svg'

function TrainSearchForm() {
  const [departureData,setDepartureDate]=useState(null)
 

  return (
    <section className="bg-dark search-form-section pt-lg-5 pb-lg-5">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
    <div className="search-form">
      <p className="h2 text-white font-weight-600 mb-3">Search for Trains</p>
      <p className="lead h5 text-white font-weight-400">Explore more deals for your Train travel</p>
      <form id="flightSearch" className="mt-4">
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                <span className="input-group-text">
                  <img src={departuresvg} alt="" />
                </span>
                  <input
                    name="departureOneWay"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure "
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="input-group">

                <span className="input-group-text">
                  <img src={arrivalsvg} alt="" />
                </span>
                  <input
                    name="arrivalOneWay"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={departureData}
          onChange={(date) => setDepartureDate(date)}
          placeholderText="Departure Date"
          className="form-control arrival-icon"
          required
        />
                </div>
              </div>
              <div className="col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary text-white w-100 btn-clr">
            <i className="bi bi-search pe-2"></i>
              Search
            </button>
          </div>
            </div>
      </form>
    </div>

    </div>
      </section>
  );
}

export default TrainSearchForm;
