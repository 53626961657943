import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HandshakeForm from './HandshakeForm';
import TransactionForm from './TransactionForm';
import TransactionFailed from '../../Components/payments/TransactionFailed';
import TransactionSuccessful from '../../Components/payments/TransactionSuccessful';
import { Container, Row, Col, Card, ProgressBar, Spinner } from "react-bootstrap";
import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";

const PaymentPage = () => {
    const [handshakeData, setHandshakeData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const success = urlParams.get('success');
        const orderId = urlParams.get('order_id');
        const ts = urlParams.get('TS');
        const rd = urlParams.get('RD');
        const rc = urlParams.get('RC');

        if (success !== null && orderId && ts && rd && rc) {
            // Store the parameters in local storage
            localStorage.setItem('success', success);
            localStorage.setItem('orderId', orderId);
            localStorage.setItem('TS', ts);
            localStorage.setItem('RD', rd);
            localStorage.setItem('RC', rc);
        }
    }, [location.search]);

    const handleHandshakeSuccess = (data) => {
        setHandshakeData(data);
    };

    const urlParams = new URLSearchParams(location.search);
    const success = urlParams.get('success');

    // if (success !== null) {
    //     // Render different content based on the value of "success"
    //     if (success === 'True') {
    //         return <div><TransactionSuccessful /></div>;
    //     } else {
    //         return <div><TransactionFailed /></div>;
    //     }
    // }

    return (
        <div>
            {success !== null ? (

                
                success === 'True' ? (

                    <div>
                          <Container className="mt-4">
                <Card className="border">
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaPlaneDeparture size={32} color="#3B76B0" />
                                            <div>Booking</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaCreditCard size={32} color="#3B76B0" />
                                            <div>Payment</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaTicketAlt size={32} color="#6c757d" />
                                            <div>E-ticket</div>
                                        </div>
                                    </Col>
                                </Row>
                                <ProgressBar className="mt-3">
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={0} className="bg-light" />
                                </ProgressBar>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
                        <TransactionSuccessful /></div>
                ) : (
                    <div>
                          <Container className="mt-4">
                <Card className="border">
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaPlaneDeparture size={32} color="#3B76B0" />
                                            <div>Booking</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaCreditCard size={32} color="#6c757d" />
                                            <div>Payment</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaTicketAlt size={32} color="#6c757d" />
                                            <div>E-ticket</div>
                                        </div>
                                    </Col>
                                </Row>
                                <ProgressBar className="mt-3">
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={0} className="bg-light" />
                                </ProgressBar>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <TransactionFailed /></div>
                )
            ) : (
                !handshakeData ? (
                    <HandshakeForm onHandshakeSuccess={handleHandshakeSuccess} />
                ) : (
                    <TransactionForm handshakeData={handshakeData} />
                )
            )}
        </div>
    );
};

export default PaymentPage;



// import React, { useState } from 'react';
// import HandshakeForm from './HandshakeForm';
// import TransactionForm from './TransactionForm';

// const PaymentPage = () => {
//     const [handshakeData, setHandshakeData] = useState(null);

//     const handleHandshakeSuccess = (data) => {
//         setHandshakeData(data);
//     };

//     return (
//         <div>
//             {!handshakeData ? (
//                 <HandshakeForm onHandshakeSuccess={handleHandshakeSuccess} />
//             ) : (
//                 <TransactionForm handshakeData={handshakeData} />
//             )}
//         </div>
//     );
// };

// export default PaymentPage;