import React from 'react';
import { Breadcrumb } from 'react-bootstrap'; // React Bootstrap se Breadcrumb component import karein
import "../assets/css/aboutUs.css"
import img1 from "../assets/about-us.png"
import img2 from "../assets/about2.jpg"
import FaqHotel from '../Components/hotel/FaqHotel';

const AboutUs = () => {
  return (
    <div>
     
      {/* Banner with Breadcrumb */}
      <div className="banner breadcrumb">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col">
              {/* Breadcrumb */}
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item >About Us</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-lg-5 mb-3 px-3 px-md-4 px-lg-5">
      {/* Main Content */}
      <div className="container my-5">
        <div className="row aboutUs-align">
          <div className="col-lg-6 col-sm-12 ">
            <h2>Who we are?</h2>
            <p>Ticketistan, One stop for all of your ticketing needs. Buy Bus, Flight, Hotels, and Events Tickets at a discounted Price. Book Online Today! </p>
            <p>
            
            Ticketistan, a product of Flight Way Ltd was founded back in 2017. The purpose of Ticketistan was to provide a seamless booking experience for travel enthusiasts all over the world. Whether you are looking to book flights or tours, Ticketistan is here to make your life easy with its user-friendly interface. Travelers from all over the world can book their Tickets, Hotels, Tours in a few clicks without any hassle. With top notch customer service and various travel related options Ticketistan is here to make your life easy.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
           <img src={img1} alt="" className='img-1'/>
          </div>
        </div>
      </div>

      <div className="container  my-5 ">
        <div className="row aboutUs-align">
            
         <div className="col-lg-6 col-sm-12">
           <img src={img2} alt="" className='img-2'/>
          </div>
          <div className="col-lg-6 col-sm-12 custom-mb aboutUs-align">
            <h2>Our Vission</h2>
            <p>To ensure a transparent, easy, and efficient e-ticketing system in Pakistan.</p>
           
          </div>
          
        </div>
      </div>
    </div>

    

    <FaqHotel/>
    
    
    </div>
  );
}

export default AboutUs;
