import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css'

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // mobile breakpoint
        settings: {
          slidesToShow: 3, // show 2 slides on mobile
        },
      },
    ],
  };

  return (

    <section className=" search-form-section pt-3">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
        <p class="h2 custom-font-bold mb-1">Top Flights Partners</p>
        <p class="lead h5 mb-3 custom-font-normal">Travel with the best Flights in Pakistan. </p>
        <div className="carasol ">
            
        
    <Slider {...settings}>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/FZ.svg`} alt="Slide 1" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/EK.svg`} alt="Slide 2" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/PK.svg`} alt="Slide 3" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/ER.svg`} alt="Slide 4" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/PA.svg`} alt="Slide 5" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/EY.svg`} alt="Slide 5" />
      </div>

      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={` https://storage.googleapis.com/bookmepk/static/images/airlines/QR.svg`} alt="Slide 5" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/TK.svg`} alt="Slide 5" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/PF.svg`} alt="Slide 5" />
      </div>
      <div className="slider-item">
        <div className="slider-overlay"></div>
        <img src={`https://storage.googleapis.com/bookmepk/static/images/airlines/9P.svg`} alt="Slide 5" />
      </div>


    </Slider>

    </div>  
        </div>
      </section>
  );
};

export default ImageSlider;
