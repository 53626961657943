import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import carasol1 from "../Images/carasol1.png";
import carasol2 from "../Images/carasol2.png";
import carasol3 from "../Images/carasol3.png";
import whyuse1 from "../Components/assets/icons/whyUseIcon1.png";
import whyuse2 from "../Components/assets/icons/whyUseIcon2.png";
import whyuse3 from "../Components/assets/icons/whyUseIcon3.png";
import MobileApp from "../Components/MobileApp";
import BusSearchForm from "../Components/bus/BusSearchForm.jsx";
import FaqHotel from "../Components/hotel/FaqHotel.jsx";

const Bus = () => {
  const [showForm, setShowForm] = useState(true);
  const [showMultiCityForm, setShowMultiCityForm] = useState(false);
  const [departureOptions, setDepartureOptions] = useState([]);
  const [loadingDepartures, setLoadingDepartures] = useState(true);

  useEffect(() => {
    // Fetch departure options when component mounts
    fetchDepartureOptions();
  }, []);

  const fetchDepartureOptions = async () => {
    try {
      const BASE_URL = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${BASE_URL}/flight/airports`); // Replace "/flight/airports" with your actual API endpoint
      setDepartureOptions(response.data);
      setLoadingDepartures(false);
    } catch (error) {
      console.error("Error fetching departure options:", error);
      setLoadingDepartures(false);
    }
  };
  const airports = [
    {
      api_id: "arp_lhe_pk",
      iata_city_code: "LHE",
      city_name: "Lahore",
      icao_code: "OPLA",
      iata_country_code: "PK",
      iata_code: "LHE",
      latitude: 31.521077,
      longitude: 74.407111,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Allama Iqbal International Airport",
    },
    {
      api_id: "arp_pew_pk",
      iata_city_code: "PEW",
      city_name: "Peshawar",
      icao_code: "OPPS",
      iata_country_code: "PK",
      iata_code: "PEW",
      latitude: 33.992877,
      longitude: 71.515714,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Bacha Khan International Airport",
    },
    {
      api_id: "arp_bhv_pk",
      iata_city_code: "BHV",
      city_name: "Bahawalpur",
      icao_code: "OPBW",
      iata_country_code: "PK",
      iata_code: "BHV",
      latitude: 29.349086,
      longitude: 71.714577,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Bahawalpur Airport",
    },
  ];

  const handleRadioChange = (event) => {
    setShowForm(
      event.target.value === "one_way" || event.target.value === "return"
    );
    setShowMultiCityForm(event.target.value === "multi_city");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle form submission
  };

  return (
    <>
     
          <BusSearchForm/>
      
      {/* <FlightSearchData/> */}
      
      <section className="quick-links-section pt-5 my-5 mt-0 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5 ">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="#mobile-app ">
                  <div className="d-flex align-items-center ">
                    <div className="side-icon">
                      <img src={icon1} />
                    </div>
                    <div className="content ms-3 ">
                      <h5>Download Ticketistan App</h5>
                      <p className="mb-0">Great detais on our mobile app</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/contact-us">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon2} />
                    </div>
                    <div className="content ms-3">
                      <h5>Help Center</h5>
                      <p className="mb-0">Search on mobile apps with ease</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/user/bookings">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon3} />
                    </div>
                    <div className="content ms-3">
                      <h5>Manage Bookings</h5>
                      <p className="mb-0">Explore and bookings hassel-frees</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="travel-partners-section">
        <div className="container pt-md-4 pb-lg-5 pt-lg-0 px-3 px-md-4 px-lg-5 ">
          <p className="h2 fs-3 custom-font-bold mb-1">Latest Offers</p>
          <p className="lead h5 custom-font-normal">
          Ticketistan furnishes affordable & comfortable services across
            Pakistan.{" "}
          </p>
          <div className="row carasolParent">
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol1} />
              </div>
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol2} />

          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol3} />

          </div>
          </div>
          </div>
      </section>

      <section className="why-use-ticketestan bg-blue mb-5">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <p className="h2 fs-3 custom-font-bold  mb-1 mt-3">
                Why Use Ticketistan?
              </p>
              <p className="lead h5 custom-font-normal ">
                We furnish ample of deals in Pakistan.
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-center mt-2">
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse1} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse2} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse3} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <FaqHotel/>

      <MobileApp/>


      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Phenomenal Online Bus Tickets Experience</h2>
      <p class="fs-6 fclr">
      Ticketestan is stressed on providing trusted and convenient online bus service, regardless of returning home city, business trip etc, you can a step away booked your seat online through Ticketestan.
      </p>
    </div>
  </div>

 
 

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">How to Book Bus Tickets through Ticketestan</h2>
     
  <ol className="fclr">
    <li>Enter the departure and destination locations in the “From” and “To” tab respectively.</li>
    <li>Select the “Date of Your Bus Trip” and hit the “Search” button</li>
    <li>Select a bus service of your choice from the list of buses that are displayed then click Ticketistan.</li>
    <li>Select a seat number and mention your gender in the seat plan</li>
    <li>Put in the “Passenger Information” and “Contact Information”</li>
    <li>You have to mention whether you are vaccinated or not.</li>
    <li>You will receive the OTP on your phone number, put it in.</li>
    <li>After filling all the information, select the payment method i.e easy paisa, debit card, and jazz cash etc.</li>
    <li>Hit the checkout button to confirm your</li>
  </ol>


      <hr class="my-4"/>
    </div>
  </div>




</div>
        </div>
      </section>
    </>
  );
};

export default Bus;
