import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

const TransactionSuccessful = () => {
  const item_id = localStorage.getItem('ItemId');
  const booking_id = localStorage.getItem('bookingId');
  const rc = localStorage.getItem('RC');
  const rd = localStorage.getItem('RD');
  const ts = localStorage.getItem('TS');
  const o = localStorage.getItem('orderId');

  console.log(item_id);
  console.log(booking_id);
  console.log(rc);
  console.log(rd);
  console.log(ts);
  console.log(o);

  const transaction = {
    id: 12,
    amount: 2000,
    date: "11/11/1111"
  };

  const handleFinalizeBooking = () => {
    // Retrieve data from local storage
   


    // Call the API
    fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/finalize-booking-on-payment-success/`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "AccessToken": localStorage.getItem('accessToken')

      },
      body: JSON.stringify({
        item_id,
        booking_id,
        RC: rc,
        RD: rd,
        TS: ts,
        O: o
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to finalize booking');
        }
      })
      .then(data => {
        // Handle the response data
        console.log(data);
      })
      .catch(error => {
        // Handle the error
        console.error(error);
      });
  };

  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Card.Body>
              <FaCheckCircle size={50} className="text-success mb-3" />
              <Card.Title>Transaction Successful!</Card.Title>
              <Card.Text>
                Your transaction has been processed successfully.
                <br />
                <strong>Transaction ID:</strong> {transaction.id}
                <br />
                <strong>Amount:</strong> {transaction.amount}
                <br />
                <strong>Date:</strong> {transaction.date}
              </Card.Text>
              <div>
                <Button className="custom-color" onClick={handleFinalizeBooking}>
                  Finalize Booking
                </Button>
                <Button className="custom-color ml-3" href="#">
                  Request a Refund
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionSuccessful;
