import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ProgressBar, Spinner } from "react-bootstrap";
import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";
function Eticket() {
    const [response, setResponse] = useState('');
    const access_token = localStorage.getItem('access_token');
    const booking_id = localStorage.getItem('bookingId');
    const booking_itemid = localStorage.getItem('booking_itemid');
    useEffect(() => {
     
        // Make the API call
        fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/issue-ticket/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'AccessToken': access_token,
                'Version': 'v1',
            },
            body: JSON.stringify({
                booking_id: booking_id,
                booking_itemid: booking_itemid,
            }),
        })
            .then(response => response.json())
            .then(data => {
                // Handle the API response
                console.log(data);
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
    }, []);

    const handleGetTicket = () => {
        // Get the required variables from local storage
        const access_token = localStorage.getItem('access_token');
        const booking_id = localStorage.getItem('booking_id');
        const booking_itemid = localStorage.getItem('booking_itemid');

        // Make the API call
        fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/get-ticket/`, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'AccessToken': access_token,
            'Version': 'v1',
            },
            body: JSON.stringify({
            booking_id: booking_id,
            booking_itemid: booking_itemid,
            }),
        })
            .then(response => response.json())
            .then(data => {
            // Handle the API response
            setResponse(data);
            })
            .catch(error => {
            // Handle any errors
            console.error(error);
            });
                // Handle the API response
             
    };

    return (
        <div>
             <Container className="mt-4">
                <Card className="border">
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaPlaneDeparture size={32} color="#3B76B0" />
                                            <div>Booking</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaCreditCard size={32} color="#3B76B0" />
                                            <div>Payment</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaTicketAlt size={32} color="#3B76B0" />
                                            <div>E-ticket</div>
                                        </div>
                                    </Col>
                                </Row>
                                <ProgressBar className="mt-3">
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={34} className="bg-info" />
                                </ProgressBar>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <button onClick={handleGetTicket}>Get eTicket</button>
            <p>{response}</p>
        </div>
    );
}

export default Eticket;