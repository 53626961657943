import React, { useState, useEffect } from "react";
import HotelSearchForm from "../Components/hotel/HotelSearchForm.jsx"
import "../Components/hotel/Hotel.css";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import img from "../assets/imgs/hotel-annu.png";
import MobileApp from "../Components/MobileApp";


const Hotel = () => {
  return (
    <>
      <HotelSearchForm />

      <section className="quick-links-section my-5 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5 ">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="#mobile-app ">
                  <div className="d-flex align-items-center ">
                    <div className="side-icon">
                      <img src={icon1} />
                    </div>
                    <div className="content ms-3 ">
                      <h5>Download Ticketistan App</h5>
                      <p className="mb-0">Great detais on our mobile app</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/contact-us">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon2} />
                    </div>
                    <div className="content ms-3">
                      <h5>Help Center</h5>
                      <p className="mb-0">Search on mobile apps with ease</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/user/bookings">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon3} />
                    </div>
                    <div className="content ms-3">
                      <h5>Manage Bookings</h5>
                      <p className="mb-0">Explore and bookings hassel-frees</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="announcements-section ">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className=" pt-5 announcement-backg">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-end">
              <div className="bottom-content   ps-4">
                <p className="h2 font-weight-600 mb-3 text-white">
                  Hotels Booking at Discounted Prices
                </p>
                <p className="lead mb-4 text-white">
                Unlock unbeatable deals on hotel bookings with Ticketestan. Experience luxury and comfort without breaking the bank. Our platform offers discounted prices on a wide range of hotels. Ticketestan is budget-friendly and memorable hotel experiences. Book now and exceptional savings on your accommodations.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="booking-annu ps-2">
                <h2 className="text-white">
                  Get Discount On <br /> Online Hotel Booking
                </h2>
                <button className="btnn">Book Now</button>
                <img src={img} alt="" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>

      <section className="top-route-section pb-5">
        <div className="container py-4 px-3 px-md-4 px-lg-5">
          <div className="border-top border-bottom">
            <div className="accordion accordion-flush" id="route">
              <div className="accordion-item">
                <h2 className="accordion-header" id="route-headingOne">
                  <button
                    className="accordion-button px-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#route-collapseOne"
                    aria-expanded="false"
                    aria-controls="route-collapseOne"
                  >
                    <h4 className="font-weight-600 text-capitalize">
                      Ticketistan Hotel Services
                    </h4>
                  </button>
                </h2>
                <div
                  id="route-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="route-headingOne"
                  data-bs-parent="#route"
                >
                  <div className="accordion-body px-0">
                    <ul className="row gy-3 list-unstyled">
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Bahawalpur
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Gilgit Baltistan
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Hunza
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Kaghan
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Karachi
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Murree
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Naran
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Skardu
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Swat
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Sialkot
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Guest Houses in Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Comfort Residency Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Shogran
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Nathia Gali
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Azad Kashmir
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Best Hotels in Neelum Valley
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Hotels in Hyderabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Hotels in Multan
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Hotels in Rawalpindi
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                          Hotels in Faisalabad
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mb-5">
        <MobileApp />
      </div>

      <section>
        <div className="container px-3 mb-5 px-md-4 px-lg-5 ">
          <p className="h2 fs-3 font-weight-600 mb-4">
            Frequently Asked Questions
          </p>

          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Why Ticketistan.com ?
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        We are Pakistan's no.1 E-ticketing platform. We let you pre-book the best deals on your tickets for Movies, Bus Travel, Flights, Train journey and Events. You no longer have to stand in lines and can purchase your tickets from the comfort of your ‘phones’ by using a variety of digital payment methods. Get your ticket confirmations instantly via SMS and Email.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        What information will I be able to see during my ticket purchase?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Using Ticketistan to purchase your tickets, you will be able to view:
        <ul>
          <li>The timings and schedules for the occasion or journey you’re trying to book.</li>
          <li>Availability and seating plans so you know exactly what you’re purchasing and that you’re paying the best price for it.</li>
          <li>A set of options for you to lock your ticket and pay using your preferred digital payment method.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        How do I know if my purchase was successful?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Upon successful completion of your ticket purchase, you will be directed to an order confirmation page which will contain an order confirmation number. With a few minutes of your purchase you will receive confirmation Email that will contain summary of your order along with E-ticket. 
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFour"
        aria-expanded="false"
        aria-controls="collapseFour"
      >
        Can I choose my own seat? 
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Absolutely! The initial step for ticket booking will allow you to view the available seats. You will be able to select from these available options and book your seat immediately. 
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFive"
        aria-expanded="false"
        aria-controls="collapseFive"
      >
        How can I purchase E-Tickets once I have selected my seats? 
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      You can purchase E-Tickets using a variety of popular online payment methods mentioned below: 
      <ul>
        <li>Debit/Credit Cards (Local &amp; International)</li>
        <li>Easypaisa</li>
        <li>Jazz Cash</li>
        <li>Bank Transfer</li>
        </ul>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSix"
        aria-expanded="false"
        aria-controls="collapseSix"
      >
        If I have a query regarding my E- Ticket, who can I contact for assistance? 
      </button>
    </h2>
    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Our Customer Service support is available 24/7 via phone (042-111-266563) and email support (contact@bookme.pk). 
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSeven"
        aria-expanded="false"
        aria-controls="collapseSeven"
      >
         What if I do not receive my confirmation email? 
      </button>
    </h2>
    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      If for some reason you did not receive your confirmation email, please check your “junk” and/or “spam” folder. In case there is another issue, please feel free to contact our 24/7 support (0304 111 0636, contact@ticketistan) 
      </div>
    </div>
  </div>   

<div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseEight"
        aria-expanded="false"
        aria-controls="collapseEight"
      >
         What are the requirements for booking a ticket? 
      </button>
    </h2>
    <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      After you have selected your desired seat/booking you may be required to fill in some details for verification and validation such as, Name, Phone number, Email and CNIC. 
      </div>
    </div>
  </div>


 
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseNine"
        aria-expanded="false"
        aria-controls="collapseNine"
      >
        What are the rewards for using Ticketistan.com? 
      </button>
    </h2>
    <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Apart from being able to easily pre-book your desired seat and ticket within a few clicks sitting at home with our platform, there are a couple of more exciting rewards that come with using the Bookme app, such as: 
      <ul>
        <li>You will get redeemable points on your bookings.</li>
        <li>Send your referral code to your friends to win exciting prizes.</li>
        <li>Achieve your milestones and claim gifts and prizes.</li>
        <li>Win exciting badges on your bookings.</li></ul>
      </div>
    </div>
  </div>


  
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTen"
        aria-expanded="false"
        aria-controls="collapseTen"
      >
         What if I have forgotten my password? 
      </button>
    </h2>
    <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      If you forget your password, just click on "Forgot Password" and provide your registered email address. An Email will be sent to you with an option to reset your password. 
      </div>
    </div>
  </div>
</div>
</div>

      </section>

      <section className="instruction pt-lg-1">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Get Variety of Hotelsto Book Online</h2>
      <p class="fs-6 fclr">
      Online hotel booking simplifiesthe travel planning process, making it more efficient and enjoyable fortravelers. Discover a world of diverse hotel options online, catering to everytravel preference:i.e Booking in mega cities as well as northern areas ofPakistan are broad based opportunities in our variety of hotels online bookingsystem round the clock availability.
      </p>
      <hr className="my-4"/>
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Book Hotels in Islamabad</h2>
      <p className="fs-6 fclr">
      The capital of Pakistan, Islamabad, is a city that blends social legacy with commercialisation. The business sectors of Rawalpindi, the Faisal Mosque, and the Margala Hills National Park are a portion of the significant places of interest in Islamabad. Since it is the capital, Islamabad has various hotels and resorts. The best lodgings in Islamabad that you can book online are:.
      </p>
      <ol className="fs-6 fclr">
        <li>Luxus Grand Hotel</li>
        <li>Ambassador Hotel</li>
        <li>Pearl Continental Hotel</li>
        <li>Nishat Hotel</li>
        <li>Indigo Heights Hotels and Suites</li>
      </ol>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 className="mb-4 fs-5 custom-font-bold">Book Hotels in Skardu</h2>
      <p className="fs-6 fclr">
      Skardu is a place situated in Pakistan at the conversion of the Indus and Shigar Rivers. It has a wonderful position on the waterfront, and its stunning mountains make numerous sceneries for tourists. It has amazing hotels for accommodations of visitors. Find out best hotels as follows to book online:
      </p>
      <ol className="fs-6 fclr"> 
        <li>Dewan e Khaas Hotel</li>
        <li>Himalaya Hotel Skardu</li>
        <li>Markhor Resort Yougo</li>
        <li>Graceland</li>
      </ol>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Book Hotels in Karachi</h2>
      <p class="fs-6 fclr">
      Karachi is the capital of Sindh and situated on the Arabian Sea. Karachi is home to Pakistan's two biggest seaports, the Port of Karachi and Port Bin Qasim. It has the most active and busy airport in Pakistan. To accommodate visitors and travellers, it has a range of luxurious hotels. You can choose from the following hotels to book online:
      </p>
      <ol className="fs-6 fclr">
        <li>Pearl Continental Hotel</li>
        <li>Hotel Crown Inn Karachi</li>
        <li>Hotel Excelsior</li>
        <li>Seaview Guest House</li>
      </ol>


      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    
      <h2 class="mb-4 fs-5 custom-font-bold">How to Book Hotels Online Through Bookme?</h2>
      <p class="fs-6 fclr">It is easy to book hotels online through Bookme. Follow the steps mentioned below:</p>
      <ol className="fs-6 fclr">
        <li>Go to the hotel page of Ticketistan website.</li>
        <li>Select your city</li>
        <li>SPut your check in and check out details.</li>
        <li>Put in the number of required rooms and number of people.</li>
        <li>Press search and select from the multiple options of hotels.</li>
        <li>Then fill in the personal details before the final booking.</li>
      </ol>
      
    </div>
  </div>




</div>
        </div>
      </section>
    </>
  );
};

export default Hotel;
