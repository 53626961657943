import React from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';

const InvoiceActions = ({ invoiceNumber, amount, status, dueDate, onSendInvoice, onEditInvoice, onAddNote }) => {
  return (
    <>
      <Row className="mb-3">
        <Col>
          <Button variant="link" className="text-dark">&lt; Back to invoices</Button>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h4>Invoice <span className="text-primary">#{invoiceNumber}</span> for ${amount.toFixed(2)}</h4>
          <Badge bg="info" className="me-2">{status}</Badge> {dueDate}
        </Col>
        <Col md={4} className="text-end">
          <Button variant="primary" className="me-2" onClick={onSendInvoice}>Send invoice</Button>
          <Button variant="secondary" className="me-2" onClick={onEditInvoice}>Edit invoice</Button>
          <Button variant="outline-secondary" onClick={onAddNote}>Add note</Button>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceActions;
