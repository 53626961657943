import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import searchDataReducer from "./searchData/searchDataSlice";


const store = configureStore({
  reducer: {
    auth: authReducer,
    searchData: searchDataReducer
  },
});

export default store;