import React, { useState } from 'react';
import axios from 'axios';
import '../Style/SignUpForm.css';
import { Link } from 'react-router-dom';

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // phone: '',
    password: '',
    confirm_password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/register/`, formData);
      console.log('SignUp successful!', response.data);

      // Save response data to local storage
      localStorage.setItem('user', JSON.stringify(response.data));
      
      // Optionally, reset the form after successful submission
      setFormData({
        name: '',
        email: '',
        // phone: '',
        password: '',
        confirm_password: ''
      });
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <div className="signup-form-container mt-5 mb-5">
      <h2>Create Account</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          placeholder="Name" 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        {/* <input 
          type="text" 
          name="phone" 
          placeholder="Phone" 
          value={formData.phone} 
          onChange={handleChange} 
          required 
        /> */}
        <input 
          type="password" 
          name="password" 
          placeholder="Password" 
          value={formData.password} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="password" 
          name="confirm_password" 
          placeholder="Confirm Password" 
          value={formData.confirm_password} 
          onChange={handleChange} 
          required 
        />
        <button type="submit">Sign Up</button>
      </form>
      <p className='pt-2'>Already have an account? <Link to="/login">Login</Link></p>
    </div>
  );
};

export default SignUpForm;
