import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

const InvoiceHistory = ({ history }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>History</Card.Title>
        <ListGroup variant="flush">
          {history.map((item, index) => (
            <ListGroup.Item key={index}>
              <i className={`bi ${item.icon}`}></i> {item.action}
              <br /><small className="text-muted">{item.date}</small>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default InvoiceHistory;
