import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';

const TransactionFailed = () => {
  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Card.Body>
              <FaTimesCircle size={50} className="text-danger mb-3" />
              <Card.Title>Transaction Failed</Card.Title>
              <Card.Text>
                Your transaction could not be processed. Please try again later.
              </Card.Text>
              <Button className='custom-color' href="#">
                Try Again
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionFailed;
