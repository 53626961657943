import React, { useState } from 'react';
import './CarRental.css'
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import car from "../assets/imgs/car.png"
import hatchbacks from '../assets/imgs/hatchbacks.png'
import sedan from '../assets/imgs/sedan.png'
import suv from '../assets/imgs/suv.png'
import luxury from '../assets/imgs/luxury.png'
import whyuse1 from "../Components/assets/icons/whyUseIcon1.png";
import whyuse2 from "../Components/assets/icons/whyUseIcon2.png";
import whyuse3 from "../Components/assets/icons/whyUseIcon3.png";
import MobileApp from '../Components/MobileApp';
import FaqHotel from '../Components/hotel/FaqHotel'
import arrivalsvg from '../Components/assets/svg/arrival.svg'
import departuresvg from '../Components/assets/svg/departure.svg'
import DatePickerIcon from '../Components/assets/svg/Datepicker.svg'

const CarRental = () => {
  const [formData, setFormData] = useState({
    tripType: '',
    city: '',
    pickupDate: '',
    pickupTime: '',
    dropoffDate: '',
    dropoffTime: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  const cities = [
    "Lahore", "Islamabad", "Karachi", "Gujrat", "Gujranwala", 
    "Sahiwal", "Sialkot", "Jhelum", "Sargodha", "Peshawar", 
    "Abbattabad", "Faisalabad", "Multan"
  ];

  return (
   <>
    <section className="bg-blac pt-lg-5 pb-lg-5 search-form-section">
      <div className="container p-4 px-lg-5">
        <div>
          <h1 className="text-white font-weight-600">Search for Cars</h1>
          <p className="lead h5 text-white font-weight-400">Find the best and most affordable cars</p>
        </div>
        <form className="mt-4" id="SearchForm" onSubmit={handleSubmit}>
          <div className="row gx-2 gy-3 position-relative">
            <div className="col-md-12 mb-3">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tripType"
                  id="intra-city"
                  value="1"
                  checked={formData.tripType === '1'}
                  onChange={handleChange}
                />
                <label className="form-check-label text-white ms-1" htmlFor="intra-city">Within City</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tripType"
                  id="inter-city"
                  value="0"
                  checked={formData.tripType === '0'}
                  onChange={handleChange}
                />
                <label className="form-check-label text-white ms-1" htmlFor="inter-city">Out of City</label>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="input-group">
                <span className="input-group-text">
                  <img src={departuresvg} alt="" />
                </span>
                <select
                  required
                  className="form-select form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  aria-label="City"
                >
                  <option hidden value="">City</option>
                  <option value="14">Lahore</option>
                  <option value="16">Islamabad</option>
                  <option value="10">Multan</option>
                  <option value="6">Faisalabad</option>
                  <option value="15">Karachi</option>
                  <option value="8">Sargodha</option>
                  <option value="7">Sialkot</option>
                  <option value="5">Sahiwal</option>
                  <option value="11">Gujranwala</option>
                  <option value="13">Rahim-Yar-Khan</option>
                  <option value="9">Peshawar</option>
                  <option value="17">Jehlum</option>
                  <option value="18">Abbotabad</option>
                  <option value="19">Gujrat</option>
                  <option value="20">Sadiqabad</option>
                  <option value="22">Swat</option>
                  <option value="23">Mardan</option>
                  <option value="24">Mansehra</option>
                  <option value="25">Muzaffarabad</option>
                  <option value="26">Quetta</option>
                  <option value="27">Hyderabad</option>
                  <option value="28">Sukkur</option>
                </select>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="input-group">
                <div className="dp__main dp__theme_light">
                  <div aria-label="Datepicker input" role="textbox" aria-multiline="false" aria-disabled="false" aria-readonly="false">
                    <div className="dp__input_wrap">
                      <input
                        className="dp__pointer dp__input_readonly dp__input dp__input_reg form-control"
                        type="date"
                        name="pickupDate"
                        placeholder="Pickup Date"
                        value={formData.pickupDate}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="input-group">
                <span className="input-group-text">
                  <img src={arrivalsvg} alt="" />
                </span>
                <select
                  required
                  className="form-select form-control"
                  name="pickupTime"
                  value={formData.pickupTime}
                  onChange={handleChange}
                  aria-label="Pickup Time"
                >
                  <option value="">Pick-up Time</option>
                  <option value="06:00:00">06:00 AM</option>
                  <option value="07:00:00">07:00 AM</option>
                  <option value="08:00:00">08:00 AM</option>
                  <option value="09:00:00">09:00 AM</option>
                  <option value="10:00:00">10:00 AM</option>
                  <option value="11:00:00">11:00 AM</option>
                  <option value="12:00:00">Noon</option>
                  <option value="13:00:00">01:00 PM</option>
                  <option value="14:00:00">02:00 PM</option>
                  <option value="15:00:00">03:00 PM</option>
                  <option value="16:00:00">04:00 PM</option>
                  <option value="17:00:00">05:00 PM</option>
                  <option value="18:00:00">06:00 PM</option>
                  <option value="19:00:00">07:00 PM</option>
                  <option value="20:00:00">08:00 PM</option>
                  <option value="21:00:00">09:00 PM</option>
                  <option value="22:00:00">10:00 PM</option>
                </select>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div className="input-group">
                <div className="dp__main dp__theme_light">
                  <div aria-label="Datepicker input" role="textbox" aria-multiline="false" aria-disabled="false" aria-readonly="false">
                    <div className="dp__input_wrap">
                      <input
                        className="dp__pointer dp__input_readonly dp__input dp__input_reg form-control"
                        type="date"
                        name="dropoffDate"
                        placeholder="Dropoff Date"
                        value={formData.dropoffDate}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md">
              <div className="input-group">
                <span className="input-group-text">
                  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.92 3.39764H17.297V1.59766C17.297 1.39874 17.218 1.20797 17.0773 1.06732C16.9367 0.926669 16.7459 0.847656 16.547 0.847656C16.3481 0.847656 16.1573 0.926669 16.0167 1.06732C15.876 1.20797 15.797 1.39874 15.797 1.59766V3.39764H8.65802V1.59766C8.65802 1.39874 8.57901 1.20797 8.43835 1.06732C8.2977 0.926669 8.10693 0.847656 7.90802 0.847656C7.70911 0.847656 7.51834 0.926669 7.37769 1.06732C7.23703 1.20797 7.15802 1.39874 7.15802 1.59766V3.39764H3.55801C2.61453 3.3987 1.70999 3.77404 1.04294 4.44128C0.375885 5.10853 0.000794034 6.01318 0 6.95667V13.5947C0.00105872 14.538 0.376279 15.4424 1.0433 16.1094C1.71033 16.7764 2.6147 17.1516 3.55801 17.1526H20.921C21.8642 17.1516 22.7685 16.7764 23.4354 16.1093C24.1022 15.4423 24.4772 14.5379 24.478 13.5947V6.95667C24.4775 6.0131 24.1025 5.10828 23.4354 4.44098C22.7683 3.77368 21.8636 3.39844 20.92 3.39764ZM22.977 13.5977C22.9765 14.1431 22.7596 14.6661 22.374 15.0519C21.9884 15.4377 21.4655 15.6548 20.92 15.6556H3.55701C3.01144 15.6548 2.48844 15.4378 2.10266 15.052C1.71688 14.6662 1.49982 14.1432 1.49902 13.5977V6.95966C1.49955 6.41391 1.71649 5.89063 2.10229 5.50464C2.4881 5.11864 3.01126 4.90143 3.55701 4.90063H20.92C21.4657 4.90143 21.9887 5.11868 22.3744 5.5047C22.76 5.89072 22.9768 6.414 22.977 6.95966V13.5977Z" fill="#667085"></path>
                    <path d="M11.344 7.68945H3.89801C3.6991 7.68945 3.50833 7.76847 3.36768 7.90912C3.22702 8.04977 3.14801 8.24054 3.14801 8.43945C3.14801 8.63837 3.22702 8.82914 3.36768 8.96979C3.50833 9.11044 3.6991 9.18945 3.89801 9.18945H11.344C11.5429 9.18945 11.7337 9.11044 11.8743 8.96979C12.015 8.82914 12.094 8.63837 12.094 8.43945C12.094 8.24054 12.015 8.04977 11.8743 7.90912C11.7337 7.76847 11.5429 7.68945 11.344 7.68945Z" fill="#667085"></path>
                    <path d="M18.2378 13.9014L21.2227 10.9164C21.2944 10.8472 21.3515 10.7645 21.3909 10.673C21.4302 10.5815 21.451 10.4831 21.4519 10.3835C21.4528 10.284 21.4339 10.1852 21.3962 10.093C21.3585 10.0008 21.3029 9.91704 21.2325 9.84659C21.1621 9.77614 21.0785 9.7204 20.9863 9.68265C20.8941 9.64489 20.7954 9.62589 20.6958 9.62671C20.5962 9.62753 20.4978 9.64818 20.4063 9.68744C20.3148 9.7267 20.232 9.78378 20.1627 9.85538L18.2857 11.7364L18.2857 7.19238C18.2857 6.99347 18.2067 6.8027 18.0661 6.66205C17.9254 6.5214 17.7346 6.44238 17.5357 6.44238C17.3368 6.44238 17.1461 6.5214 17.0054 6.66205C16.8647 6.8027 16.7857 6.99347 16.7857 7.19238L16.7857 11.4994L15.1418 9.85538C15.0725 9.78378 14.9897 9.7267 14.8982 9.68744C14.8067 9.64818 14.7083 9.62753 14.6087 9.62671C14.5091 9.62589 14.4104 9.64489 14.3182 9.68265C14.2261 9.7204 14.1423 9.77614 14.0719 9.84659C14.0015 9.91704 13.9459 10.0008 13.9082 10.093C13.8706 10.1852 13.8517 10.284 13.8526 10.3835C13.8535 10.4831 13.8742 10.5815 13.9135 10.673C13.9529 10.7645 14.0101 10.8472 14.0818 10.9164L17.0667 13.9014C17.2327 14.0366 17.4402 14.1104 17.6543 14.1104C17.8683 14.1104 18.0758 14.0366 18.2417 13.9014L18.2378 13.9014Z" fill="#667085"></path>
                    <path d="M8.48001 11.3608H3.89801C3.6991 11.3608 3.50833 11.4399 3.36768 11.5805C3.22702 11.7212 3.14801 11.9119 3.14801 12.1108C3.14801 12.3098 3.22702 12.5005 3.36768 12.6412C3.50833 12.7818 3.6991 12.8608 3.89801 12.8608H8.48001C8.67892 12.8608 8.86969 12.7818 9.01035 12.6412C9.151 12.5005 9.23001 12.3098 9.23001 12.1108C9.23001 11.9119 9.151 11.7212 9.01035 11.5805C8.86969 11.4399 8.67892 11.3608 8.48001 11.3608Z" fill="#667085"></path>
                  </svg>
                </span>
                <select
                  required
                  className="form-select form-control"
                  name="dropoffTime"
                  value={formData.dropoffTime}
                  onChange={handleChange}
                  aria-label="Dropoff Time"
                >
                  <option value="">Drop-off Time</option>
                  <option value="07:00:00">07:00 AM</option>
                  <option value="08:00:00">08:00 AM</option>
                  <option value="09:00:00">09:00 AM</option>
                  <option value="10:00:00">10:00 AM</option>
                  <option value="11:00:00">11:00 AM</option>
                  <option value="12:00:00">Noon</option>
                  <option value="13:00:00">01:00 PM</option>
                  <option value="14:00:00">02:00 PM</option>
                  <option value="15:00:00">03:00 PM</option>
                  <option value="16:00:00">04:00 PM</option>
                  <option value="17:00:00">05:00 PM</option>
                  <option value="18:00:00">06:00 PM</option>
                  <option value="19:00:00">07:00 PM</option>
                  <option value="20:00:00">08:00 PM</option>
                  <option value="21:00:00">09:00 PM</option>
                  <option value="22:00:00">10:00 PM</option>
                  <option value="23:00:00">11:00 PM</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <button type="submit" className="btn btn-secondary text-white w-100">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section className="quick-links-section my-5 d-md-none d-lg-block">
    <div className="container px-3 px-md-4 px-lg-5 ">
      <div className="row g-3 justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="quick-link-box h-100 border p-2 rounded">
            <a href="#mobile-app ">
              <div className="d-flex align-items-center ">
                <div className="side-icon">
                  <img src={icon1} />
                </div>
                <div className="content ms-3 ">
                  <h5>Download Ticketistan App</h5>
                  <p className="mb-0">Great detais on our mobile app</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="quick-link-box h-100 border p-2 rounded">
            <a href="/contact-us">
              <div className="d-flex  align-items-center">
                <div className="side-icon">
                  <img src={icon2} />
                </div>
                <div className="content ms-3">
                  <h5>Help Center</h5>
                  <p className="mb-0">Search on mobile apps with ease</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="quick-link-box h-100 border p-2 rounded">
            <a href="/user/bookings">
              <div className="d-flex  align-items-center">
                <div className="side-icon">
                  <img src={icon3} />
                </div>
                <div className="content ms-3">
                  <h5>Manage Bookings</h5>
                  <p className="mb-0">Explore and bookings hassel-frees</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  
  <section className="my-5 d-md-none d-lg-block">
    <div className="container px-3 px-md-4 px-lg-5 ">
    <div className="container my-5">
      <div className="row bg-car pt-lg-5 pb-lg-5">
        <div className="col-md-6 text-white">
          <h2>Rent A Car with Chauffeur</h2>
          <p>
            Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.
          </p>
          <button className="btn btn-warning btn-lg">PKR 3500 Per Day</button>
        </div>
        <div className="col-md-6">
          <img src={car} alt="Car" className="img-fluid" />
        </div>
      </div>
    </div>
        </div>
  </section>

  <section className="my-5 d-md-none d-lg-block">
      <div className="container px-3 px-md-4 px-lg-5">
        <div className="container my-5">
          <h2>Per Day Car Rental Prices in-City</h2>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>City</th>
                  <th><img src={hatchbacks} alt="Hatchback" className="img-fluid" /></th>
                  <th><img src={sedan} alt="Sedan" className="img-fluid" /></th>
                  <th><img src={suv} alt="SUV" className="img-fluid" /></th>
                  <th><img src={luxury} alt="Luxury" className="img-fluid" /></th>
                </tr>
              </thead>
              <tbody>
                {cities.map((city, index) => (
                  <tr key={index}>
                    <td>{city}</td>
                    <td>PKR 3149-3499</td>
                    <td>PKR 3149-3499</td>
                    <td>PKR 3149-3499</td>
                    <td>PKR 3149-3499</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section className="why-use-ticketestan bg-blue mb-5">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <p className="h2 fs-3 custom-font-bold  mb-1 mt-3">
                Why Use Ticketistan?
              </p>
              <p className="lead h5 custom-font-normal ">
                We furnish ample of deals in Pakistan.
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-center mt-2">
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse1} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse2} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse3} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-route-section pb-5">
        <div className="container py-4 px-3 px-md-4 px-lg-5">
          <div className="border-top border-bottom">
            <div className="accordion accordion-flush" id="route">
              <div className="accordion-item">
                <h2 className="accordion-header" id="route-headingOne">
                  <button
                    className="accordion-button px-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#route-collapseOne"
                    aria-expanded="false"
                    aria-controls="route-collapseOne"
                  >
                    <h4 className="font-weight-600 text-capitalize">
                    Ticketestan car rental Services
                    </h4>
                  </button>
                </h2>
                <div
                  id="route-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="route-headingOne"
                  data-bs-parent="#route"
                >
                  <div className="accordion-body px-0">
                    <ul className="row gy-3 list-unstyled">
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Gujranwala
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Faisalabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent A Car in Sahiwal
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent A Car in Peshawar
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent A Car in Sargodha
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Gujrat
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Abbottabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Sialkot
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Karachi
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Luxury Car Rental Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Suzuki Alto in Karachi
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Limousine in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Coaster for Rent in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Hiace Grand Cabin for Rent in Islamabad
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent A Car in Multan
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Luxury Car Rental in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Hiace Grand Cabin for Rent in Lahore
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Hiace Grand Cabin for Rent in Karachi
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rent a Car in Jhelum
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <MobileApp/>

    <div className='pt-5'>
    <FaqHotel/> 
      </div>   

      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Ticketestan  - Car Rental in Pakistan</h2>
      <p class="fs-6 fclr">
      Ticketestan offers reliable and convenient car rental services inPakistan, ensuring a seamless travel experience for customers. With a diversefleet of vehicles, competitive pricing, and user-friendly booking options,Ticketestan is your go-to choice for exploring Pakistan with comfort andflexibility. Whether for business or leisure, trust Ticketestan to provide ahassle-free car rental solution tailored to your needs.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Lahore</h2>
      <p class="fs-6 fclr">
      Discover the vibrant city ofLahore with ease through our premier car rental services. From compact cars tospacious SUVs, our fleet at Ticketestan Lahore ensures a comfortable andreliable journey. Convenient booking options, competitive rates, and excellentcustomer service make Ticketestan the preferred choice for exploring Lahore atyour own pace. Enjoy the freedom of the road with Ticketestan's car rental inLahore.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Islamabad</h2>
      <p class="fs-6 fclr">
      Embark on a seamless journeythrough Islamabad with Ticketestan's exceptional car rental services. Ourdiverse fleet, featuring everything from compact cars to SUVs, caters to yourtravel needs. With convenient booking, competitive rates, and reliablevehicles, Ticketestan ensures a smooth and enjoyable exploration of Islamabad'sscenic landscapes and vibrant culture. Choose Ticketestan for a hassle-free carrental experience in the capital city.
      </p>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Rawalpindi</h2>
      <p class="fs-6 fclr">
      Navigate Rawalpindieffortlessly with Ticketestan's reliable car rental services. Offering a diverserange of vehicles, from compact to spacious options, we ensure a comfortableand convenient travel experience. With straightforward booking, competitiverates, and a commitment to customer satisfaction, Ticketestan is your go-tochoice for exploring Rawalpindi with flexibility and ease. Enjoy the freedom todiscover the city on your terms with Ticketestan's car rental in Rawalpindi.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Faisalabad</h2>
      <p class="fs-6 fclr">
      
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold"></h2>
      <p class="fs-6 fclr">
      Explore Faisalabad at yourown pace with Ticketestan's premium car rental services. Our fleet, featuring avariety of vehicles, ensures a comfortable and reliable travel experience. Withstraightforward booking, competitive rates, and a commitment to customersatisfaction, Ticketestan is your trusted partner for hassle-free explorationof Faisalabad. Embrace the convenience and flexibility of our car rentalservices as you navigate the city effortlessly.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Gujranwala</h2>
      <p class="fs-6 fclr">
      Uncover Gujranwala's charmwith Ticketestan's exceptional car rental services. Our diverse fleet caters toyour travel needs, offering everything from compact cars to spacious SUVs. Withstraightforward booking, competitive rates, and reliable vehicles, Ticketestanensures a seamless and enjoyable exploration of Gujranwala. Choose Ticketestanfor a hassle-free car rental experience and the freedom to discover Gujranwalaat your own pace.
      </p>
    </div>
  </div>
  <hr class="my-4"/>

  

 
 

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">How to Rent a Car online through Ticketistan</h2>
     
  <ol className="fclr">
    <li> Visit car section on our web or mobile app</li>
    <li> Select your city.</li>
    <li> Putin pick & drop off cities.</li>
    <li>Provide your contact details</li>
    <li>Make payment with your desired method and confirm your booking</li>
    <li>Ensure your car is on the way.</li>
  </ol>
    </div>
  </div>




</div>
        </div>
      </section>    

   </>
  );
};

export default CarRental;
