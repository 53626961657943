import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../flights/FlightSearchForm.css'
import arrivalsvg from '../assets/svg/arrival.svg'
import departuresvg from '../assets/svg/departure.svg'
import DatePickerIcon from '../assets/svg/Datepicker.svg'


function BusSearchForm() {
  const [flightType, setFlightType] = useState('oneWay');
  const [selectedOption, setSelectedOption] = useState("adults");
  const [departureData,setDepartureDate]=useState(null)
  const [arrivalDate,setArrivalDate]=useState(null)
  
  const handleFlightTypeChange = (e) => {
    setFlightType(e.target.value);
    setSelectedOption(e.target.value);
  };

  

  return (
    <section className="bg-dark search-form-section pt-lg-5 pb-lg-5">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
    <div className="search-form">
      <p className="h2 text-white font-weight-600 mb-3">Search for Bus</p>
      <p className="lead h5 text-white font-weight-400">Explore more deals for your Bus travel</p>
      <form id="flightSearch" className="mt-4">
        <div className="row gx-2 gy-3 position-relative">
          <div className="col-md-6 mb-3">
            <div className="form-check form-check-inline">
              <input 
                className="form-check-input bg-transparent border border-white" 
                type="radio" 
                name="type" 
                id="one_way" 
                value="oneWay" 
                checked={flightType === "oneWay"}
                onChange={handleFlightTypeChange}
              />
              <label className="form-check-label text-white" htmlFor="one_way">One Way</label>
            </div>
            <div className="form-check form-check-inline">
              <input 
                className="form-check-input bg-transparent border border-white" 
                type="radio" 
                name="type" 
                id="roundway" 
                value="roundTrip" 
                checked={flightType === "roundTrip"}
                onChange={handleFlightTypeChange}
              />
              <label className="form-check-label text-white" htmlFor="roundway">Round Trip</label>
            </div>
          </div>
        </div>

        {/* One Way Form */}
        {flightType === "oneWay" && (
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-4 col-md-6">
                <div className="input-group">

                <span className="input-group-text">
                    <img src={arrivalsvg} alt="" />
                </span>
                  <input
                    name="departureOneWay"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure "
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="input-group">

                <span className="input-group-text">
                  <img src={departuresvg} alt="" />
                </span>
                  <input
                    name="arrivalOneWay"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival "
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
            
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={departureData}
          onChange={(date) => setDepartureDate(date)}
          placeholderText="Departure Date"
          className="form-control arrival-icon"
          required
        />
                </div>
              </div>

              <div className="col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary text-white w-100 btn-clr">
            <i className="bi bi-search pe-2"></i>
              Search
            </button>
          </div>
            </div>
          
        )}

        {/* Round Trip Form */}
        {flightType === "roundTrip" && (
          <div>
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                <span className="input-group-text">
                  <img src={arrivalsvg} alt="" />
                </span>
                  <input
                    name="departureRoundTrip"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                <span className="input-group-text">
                  <img src={departuresvg} alt="" />
                </span>
                  <input
                    name="arrivalRoundTrip"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival "
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={departureData}
          onChange={(date) => setDepartureDate(date)}
          placeholderText="Departure Date"
          className="form-control arrival-icon"
          required
        />
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={arrivalDate}
          onChange={(date) => setArrivalDate(date)}
          placeholderText="Arrival Date"
          className="form-control arrival-icon"
          required
        />
                </div>
              </div>
              <div className="col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary text-white w-100 btn-clr">
            <i className="bi bi-search pe-2"></i>
              Search
            </button>
          </div>
            </div>
          </div>
        )}
      </form>
    </div>

    </div>
      </section>
  );
}

export default BusSearchForm;
