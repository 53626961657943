import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  ProgressBar,
  Badge,
} from "react-bootstrap";
import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleCard } from "../../api/flights";
import { useSelector, useDispatch } from 'react-redux';

const PassengerDetailsPage = () => {

  // flight card
  const ApiAccessToken = localStorage.getItem("ApiAccessToken")
  const { flightId } = useParams();
  const [loading, setLoading] = useState(false);
  const [flightOffer, setFlightOffer] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getSingleCard(flightId, ApiAccessToken);
      // debugger;
      setFlightOffer(response?.data); // Ensure you set the response data
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect API CALL
  useEffect(() => {
    fetchData();
  }, []);


  // Extract flight details from flightOffer

  const segment = flightOffer?.segments?.[0];
  const carrier = segment?.carrier;
  const origin = segment?.origin;
  const destination = segment?.destination;

  const departureDate = origin ? new Date(origin.date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }) : '';

  const departureTime = origin ? new Date(`${origin.date}T${origin.time}`).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }) : '';

  const arrivalTime = destination ? new Date(`${destination.date}T${destination.time}`).toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }) : '';

  const flightDurationHours = segment ? Math.floor(segment.flightTime / 60) : 0;
  const flightDurationMinutes = segment ? segment.flightTime % 60 : 0;
  const flightDuration = `${flightDurationHours}h ${flightDurationMinutes}m`;

  //select api end

  console.log(flightOffer , "flight offer");
  

  const username = useSelector((state) => state.auth.user.name)

  
  const [dob, setDob] = useState(null);
  const [issuanceDate, setIssuanceDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [formValues, setFormValues] = useState([]);
  const navigate =useNavigate();

  const formatDate = (date) => {
    if (!date) return null;
    return date.toISOString().split("T")[0];
  };
  
  const seatsCount = JSON.parse(localStorage.getItem("SeatsCount"));

  const generateSeats = (seatType, count) => {
    return Array.from({ length: count }, (_, index) => ({
      name: seatType,
      id: index + 1,
    }));
  };

  let startId = 1;
  const totalSeats = Object?.entries(seatsCount).flatMap(([type, count]) =>
    generateSeats(type, count).map((seat) => ({ ...seat, id: startId++ }))
  );

  const handleChange = (index, field, value) => {
    const newFormValues = [...formValues];
    if (!newFormValues[index]) {
      newFormValues[index] = {};
    }
    newFormValues[index][field] = value;
    setFormValues(newFormValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const passengers = formValues.map((form, index) => ({
      type: "ADT",
      title: form.title,
      firstName: form.first_name,
      lastName: form.last_name,
      nationality: form.nationality,
      birthDate: form.dob,
      gender: form.gender,
      passport: {
        type: "P",
        number: form.passport_number,
        issueCountry: form.passport_issuing_country,
        expiry: form.expiry_date,
      } ,
      // issuance_date: form.issuance_date,
    }));

    const contactsForAPi = {
        title: "MR",
        firstName: "username",
        lastName: "last name",
        phone: "+49-12-3456789",
        email: "user_23432@gmail.com",
        country: "DE",
        zip: "34663",
        city: "Berlin",
        address: "Some street 1."
    }

    const requestBody = {
      flight_id: 1,
      passengers: passengers,
      contact : contactsForAPi, 
      "invoice": {
        "name": "Multiresisen Gmbh.",
        "country": "DE",
        "zip": "34663",
        "city": "Berlin",
        "address": "Some street 1."
    },
    "options": [
        {
            "id": "SpeedyBoarding",
            "value": "1"
        }
    ],
    "paymentId": "9"
    };

    const token = localStorage.getItem("token")

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/add-passengers/${flightId}/`, {
        method: "POST",
        headers: {
          "AccessToken": ApiAccessToken,
          "Content-Type": "application/json",
          // "Authorization":`Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        const bookingId = responseData.response.data.bookingId;
        localStorage.setItem("bookingId", bookingId);
        console.log("Passengers added successfully!");
        navigate('/payment'); 
      } else {
        console.error("Error adding passengers");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };



  return (
    <>
      <Container className="mt-4 sm:mt-4 md:mt-8 lg:mt-8 xl:mt-8 xxl:mt-8">
        <Card className="border">
          <Card.Body>
            <Row>
              <Col xs={12}>
                <Row className="d-flex justify-content-between align-items-center">
                  <Col xs={4} className="text-center">
                    <div>
                      <FaPlaneDeparture size={32} color="#3B76B0" />
                      <div>Booking</div>
                    </div>
                  </Col>
                  <Col xs={4} className="text-center">
                    <div>
                      <FaCreditCard size={32} color="#6c757d" />
                      <div>Payment</div>
                    </div>
                  </Col>
                  <Col xs={4} className="text-center">
                    <div>
                      <FaTicketAlt size={32} color="#6c757d" />
                      <div>E-ticket</div>
                    </div>
                  </Col>
                </Row>
                <ProgressBar className="mt-3">
                  <ProgressBar now={33} className="bg-info" />
                  <ProgressBar now={0} className="bg-light" />
                  <ProgressBar now={0} className="bg-light" />
                </ProgressBar>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <Container className="mt-5 mb-5 d-lg-flex justify-content-between">
        <div className="details col-lg-7 col-sm-12">
          {totalSeats?.map((item, index) => (
            <div className="card mt-3" key={index}>
              <div className="card-body">
                <h5 className="card-title">
                  Passenger {index + 1} ({item?.name})
                </h5>
                <form>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`title-${index}`}
                        id={`mr-${index}`}
                        value="Mr"
                        onChange={(e) =>
                          handleChange(index, "title", e.target.value)
                        }
                        required
                      />
                      <label className="form-check-label" htmlFor={`mr-${index}`}>
                        Mr.
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`title-${index}`}
                        id={`mrs-${index}`}
                        value="Mrs"
                        onChange={(e) =>
                          handleChange(index, "title", e.target.value)
                        }
                        required
                      />
                      <label className="form-check-label" htmlFor={`mrs-${index} required`}>
                        Mrs.
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`title-${index}`}
                        id={`miss-${index}`}
                        value="Miss"
                        onChange={(e) =>
                          handleChange(index, "title", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor={`miss-${index}`}>
                        Miss.
                      </label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor={`firstName-${index}`} className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`firstName-${index}`}
                        onChange={(e) =>
                          handleChange(index, "first_name", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`lastName-${index}`} className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`lastName-${index}`}
                        onChange={(e) =>
                          handleChange(index, "last_name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor={`nationality-${index}`} className="form-label">
                        Nationality
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`nationality-${index}`}
                        onChange={(e) =>
                          handleChange(index, "nationality", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6">
      <label htmlFor={`dob-${index}`} className="form-label">
        Date of Birth
      </label>
      <DatePicker
        selected={dob}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        onChange={(date) => {
          const formattedDate = formatDate(date);
          setDob(date);
          handleChange(index, "dob", formattedDate);
        }}
        dateFormat="yyyy/MM/dd"  // Optional: to format the date display
        className="form-control"
        placeholderText="Select date"
      />
    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Gender</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`gender-${index}`}
                        id={`male-${index}`}
                        value="M"
                        onChange={(e) =>
                          handleChange(index, "gender", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor={`male-${index}`}>
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`gender-${index}`}
                        id={`female-${index}`}
                        value="F"
                        onChange={(e) =>
                          handleChange(index, "gender", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor={`female-${index}`}>
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label
                        htmlFor={`passportNumber-${index}`}
                        className="form-label"
                      >
                        Passport Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`passportNumber-${index}`}
                        onChange={(e) =>
                          handleChange(index, "passport_number", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor={`passportCountry-${index}`}
                        className="form-label"
                      >
                        Passport Issuing Country
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`passportCountry-${index}`}
                        onChange={(e) =>
                          handleChange(index, "passport_issuing_country", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor={`issuanceDate-${index}`} className="form-label">
                        Issuance Date
                      </label>
                      <DatePicker
                        selected={issuanceDate}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        onChange={(date) => {
                         const formattedDate = formatDate(date);
                          setIssuanceDate(date);
                          handleChange(index, "issuance_date", formattedDate);
                        }}
                        className="form-control"
                        placeholderText="Select date"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor={`expiryDate-${index}`} className="form-label">
                        Expiry Date
                      </label>
                      <DatePicker
                        selected={expiryDate}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        onChange={(date) => {
                          const formattedDate = formatDate(date);
                          setExpiryDate(date);
                          handleChange(index, "expiry_date", formattedDate);
                        }}
                        className="form-control"
                        placeholderText="Select date"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ))}

          <Card className="mt-3">
            <Card.Body>
              <Card.Title>Contact Details</Card.Title>
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text" placeholder="e.g. 03320234557" />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="e.g. name@outlook.com" />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="12">
                    <Form.Check
                      type="checkbox"
                      label="I agree to receive travel related information and deals"
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Card.Body>
          </Card>

          <Button variant="primary" className="mt-2" onClick={handleSubmit}>
            Continue
          </Button>
        </div>

        <div className="col-lg-4 col-sm-12">
        <h5>Your Bookings</h5>
          {flightOffer && (
            <Card className="border mb-3">
              <Card.Body>
                <Row className="align-items-center mb-2">
                  <Col xs={12}>
                    <Badge bg="light" text="dark" className="mb-2">
                      Departing • {departureDate}
                    </Badge>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={2}>
                    <img src="" alt={carrier?.name} style={{ width: "30px" }} />
                  </Col>
                  <Col xs={10}>
                    <span>{carrier?.name} {carrier?.code}-{carrier?.flightNumber}</span>
                  </Col>
                </Row>
                <Row className="align-items-center my-2">
                  <Col xs={5}>
                    <h5 className="m-0">{departureTime}</h5>
                  </Col>
                  <Col xs={2} className="text-center">
                    <Badge bg="light" text="dark">
                      {flightDuration}
                    </Badge>
                  </Col>
                  <Col xs={5} className="text-end">
                    <h5 className="m-0">{arrivalTime}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{origin?.name} ({origin?.code}) - Nonstop - {destination?.name} ({destination?.code})</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <span>🧳 Total: 30kg</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}


          <Card className="mt-4">
            <Card.Body>
              <Card.Text>
                <i className="bi bi-box-arrow-in-right me-2"></i>
                Sign in or create an account for faster bookings!
              </Card.Text>
              <Button variant="outline-primary" className="w-100 mb-2">
                Sign In
              </Button>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Price Summary</Card.Title>
              <Card.Text>
                {/* You can add price details here dynamically if available */}
                Price you pay: {flightOffer ? `${flightOffer.price.currency} ${flightOffer.price.total.toFixed(2)}` : 'Loading...'}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default PassengerDetailsPage;
 