import React from 'react';
import app1 from "../assets/images/app1.png"
import app2 from "../assets/images/app2.png"
import logo from "./Logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faYoutube, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { useLocation } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';


const Footer = () => {

  const location = useLocation();
  const { width } = useWindowSize();

  if (location.pathname === "/" && width < 600) {
    return null; // Hide footer on home page for mobile view
  }

  return (
    <>
     <section className="newsletter-section bg-gray-900 backgroundclrOne" id="newsLetter">
      <div className="container py-4   px-3 px-md-4 px-lg-5">
        <div className="row my-1 gy-3">
          <div className="col-lg-7">
            <p className="h2 fs-3 custom-font-bold mb-3 text-white">Subscribe to our newsletter</p>
            <p className="lead h5 custom-font-normal text-white">Stay up to date with the latest news, announcements and articles.</p>
          </div>
          <div className="col-lg-5">
            <form action="#">
              <div className="row g-2 gy-3">
                <div className="col-md-9">
                  <input type="email" className="form-control" placeholder="Enter your email" required />
                </div>
                <div className="col-md-3">
                  <button type="submit" className="btn globalclr">Subscribe</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section className="footer-section bg-gray-800" id="footer">
      <div className="container py-5 py-lg-5 px-3 px-md-4 px-lg-5" >
        <div className="row g-3 my-2">
          <div className="col-lg-3 col-md-6 col-6">
            <h6 className="font-weight-600 small text-gray-400 mb-3 tect-white text-white">Services</h6>
            <ul className="nav flex-column ">
              <li className="nav-item"><a href="/buy-bus-tickets-online" className="nav-link text-white px-0">Bus Booking</a></li>
              <li className="nav-item"><a href="/book-flights-online" className="nav-link text-white px-0">Flights Booking</a></li>
              <li className="nav-item"><a href="/movie-tickets-online" className="nav-link text-white px-0">Movie Booking</a></li>
              <li className="nav-item"><a href="/event-tickets-online" className="nav-link text-white px-0">Event Booking</a></li>
              <li className="nav-item"><a href="/train-tickets-booking-online" className="nav-link text-white px-0">Train Booking</a></li>
              <li className="nav-item"><a href="/car-rental-booking-online" className="nav-link text-white px-0">Car Rental Booking</a></li>
              <li className="nav-item"><a href="/book-hotels-online" className="nav-link text-white px-0">Hotels Booking</a></li>
              <li className="nav-item"><a href="/tour-tickets-online" className="nav-link text-white px-0">Tours Booking</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-6">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Company</h6>
            <ul className="nav flex-column">
              <li className="nav-item"><a href="/about-us" className="nav-link text-white px-0">About us</a></li>
              <li className="nav-item"><a href="/faq" className="nav-link text-white px-0">FAQ</a></li>
              <li className="nav-item"><a href="/contact-us"  className="nav-link text-white px-0">Contact us</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Blogs</a></li>
              <li className="nav-item"><a href="/announcements" className="nav-link text-white px-0">Announcements</a></li>
              <li className="nav-item"><a href="/terms" className="nav-link text-white px-0">Terms &amp; Conditions</a></li>
              <li className="nav-item"><a href="/privacy" className="nav-link text-white px-0">Privacy Policy</a></li>
            </ul>
          </div>

          <div className="col-md-6 col-lg-3 col-6">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Top Flights</h6>
            <ul className="nav flex-column">
              <li className="nav-item"><a href="/flights/pia-online-ticket-booking" className="nav-link text-white px-0">PIA Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Serene Air Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Air Blue Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Emirates Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Etihad Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Qatar Airways Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Turkish Airlines Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Air Sial Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Fly Dubai Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">FlyJinnah Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Jazeera Airways Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Malindo Air Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Oman Air Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Srilankan Airlines Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Kuwait Airways Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">FlyBaghdad Booking</a></li>
              
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 col-6">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Top Buses</h6>
            <ul className="nav flex-column">
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Road Master Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Kainat Travels Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Silk Line Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">PAKLINES Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Bashir Sons Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">FM Bilal Travel Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Khan Brothers Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Skyways Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">Daewoo Express Booking</a></li>
              <li className="nav-item"><a href="" className="nav-link text-white px-0">QConnect Booking</a></li>
              <li>this is ali adding somthing</li>
            </ul>
          </div>
          {/* <div className="col-lg-3 col-md-12 col-12">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Get the app</h6>
            <a className="mb-lg-2 me-lg-0 me-2 d-lg-block d-inline-block" target="_blank" href=""  >
              <img className="app-download" src={app1} alt="Download from App Store" />
            </a>
            <a className="d-lg-block d-inline-block" target="_blank" href="https://play.google.com/store/apps/details?id=com.bookme.bookme&hl=en">
              <img className="app-download" src={app2} alt="Download from Google Play" />
            </a>
          </div> */}

        {/* <div className="col-lg-3 col-md-12 col-12">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Get the app</h6>
            <div className="d-flex mt-4 mb-5">
                <div className="me-1">
                  <a href="https://itunes.apple.com/pk/app/bookme-ticket-booking/id1210023165?mt=8" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/ios-app.6f57cc8d.svg" alt="Download IOS App" />
                  </a>
                </div>
                <div className="ms-1">
                  <a href="https://play.google.com/store/apps/details?id=com.macropaks.bookme&amp;hl=en_US" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/android-app.cda23a2c.svg" alt="Download Android App" />
                  </a>
                </div>
              </div>
          </div> */}

          
          {/* <div className="col-lg-3 col-md-12 col-12">
            <h6 className="font-weight-600 small text-gray-400 mb-3 text-white">Follow us</h6>
            <ul className="list-inline social-links">
              <li className="list-inline-item"><a href="https://www.facebook.com/BookmePK/" className="text-white"><i className="fab fa-facebook-f"></i></a></li>
              <li className="list-inline-item"><a href="https://twitter.com/bookmepk" className="text-white"><i className="fab fa-twitter"></i></a></li>
              <li className="list-inline-item"><a href="https://www.linkedin.com/company/bookme-pk/" className="text-white"><i className="fab fa-linkedin-in"></i></a></li>
              <li className="list-inline-item"><a href="https://www.instagram.com/bookmepk/" className="text-white"><i className="fab fa-instagram"></i></a></li>
            </ul>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-lg-8 col-md-6 col-12 my-3">
            <p className="text-white small">&copy; 2023 Bookme.pk All rights reserved.</p>
          </div>
          <div className="col-lg-4 col-md-6 col-12 my-3">
            <ul className="list-inline text-lg-end text-md-center text-sm-center text-xs-center social-links">
              <li className="list-inline-item"><a href="/about-us" className="text-white small me-2">About</a></li>
              <li className="list-inline-item"><a href="/faq" className="text-white small me-2">FAQ</a></li>
              <li className="list-inline-item"><a href="/terms" className="text-white small me-2">Terms</a></li>
              <li className="list-inline-item"><a href="/privacyPolicy" className="text-white small me-2">Privacy</a></li>
            </ul>
          </div>
        </div> */}
      </div>
      <hr className='hr-color'/>

     
    <div className="container py-4 py-lg-5 px-3 px-md-4 px-lg-5">
      <div className="row g-3 g-lg-1">
        <div className="col-6">
          <a href="/" aria-label="Ticketistan">
          <img src={logo } alt="Logo" className="img-fluid" />
          </a>
        </div>
        <ul className="nav social-platforms col-6 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-white" target="_blank" href="" aria-label="Twitter">
            <FontAwesomeIcon icon={faTwitter}  />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-white" target="_blank" href="" aria-label="Linkedin">
            <FontAwesomeIcon icon={faFacebookF}  />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-white" target="_blank" href="" aria-label="Facebook">
            <FontAwesomeIcon icon={faYoutube}  />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-white" target="_blank" href="https://pk.linkedin.com/company/ticketistandotcom" aria-label="Youtube">
            <FontAwesomeIcon icon={faLinkedinIn}  />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-white" target="_blank" href="" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram}  />
            </a>
          </li>
        </ul>
      </div>
    </div>
    </section>

    
    </>
  );
};

export default Footer;
