// PrivacyPolicy.js
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <>
       {/* Banner with Breadcrumb */}
       <div className="terms-banner breadcrumb">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col">
              {/* Breadcrumb */}
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item >Privacy Policy</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">

    <div className="container mt-5">
  <div className="row">
    <div className="col-12 p-0">
    <p className="fs-6 fclr">
    This Privacy Policy outlines how Ticketistan.com, as a ticketing agency, handles and protects the privacy of event organizers and their customers.e.
      </p>
      <h2 className="mb-4 fs-5 custom-font-bold">1.Information Collection</h2>
      <p className="fs-6 fclr">
      a. Ticketistan.com collects event-related information provided by organizers.
      
      </p>
      <p className="fs-6 fclr">b. Customer data collected during ticket transactions includes name, contact details, and payment information.
</p>
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    
      <h2 className="mb-4 fs-5 custom-font-bold">2.Use of Information</h2>
      <p className="fs-6 fclr">
      a. Event organizers' information is used for communication, event promotion, and payment processing.
      </p>
      <p className="fs-6 fclr">
      b. Customer data is utilized to facilitate ticket transactions and provide customer support.
      </p>
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    
      <h2 className="mb-4 fs-5 custom-font-bold">3.Data Security</h2>
      <p className="fs-6 fclr">
      a. Ticketistan.com employs industry-standard security measures to protect both organizer and customer information.
      </p>
      <p className="fs-6 fclr">
      b. Access to sensitive data is restricted to authorized personnel only.
</p>
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    
      <h2 className="mb-4 fs-5 custom-font-bold">4.Third-Party Services</h2>
      <p className="fs-6 fclr">
      a. Ticketistan.com may use third-party services for payment processing and analytics.
      </p>
      <p className="fs-6 fclr">
      b. These third parties are obligated to protect information in accordance with their own privacy policies.
      </p>
      
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    <h2 className="mb-4 fs-5 custom-font-bold">5.Data Retention</h2>
      <p className="fs-6 fclr">
      a. Organizer information is retained as long as their account is active.
      </p>
      <p className="fs-6 fclr">
      b. Customer data is stored for transaction purposes and may be retained for a reasonable period.
      </p>
      
    </div>
  </div>

  <div className="row">
    <div class="col-12 p-0">
    <h2 class="mb-4 fs-5 custom-font-bold">6.Organizer Access to Data</h2>
      <p class="fs-6 fclr">
      a. Organizers have limited access to customer data for event management purposes.
      </p>
      <p class="fs-6 fclr">
      b. Organizers are responsible for the privacy and security of the data they access.
      </p>
      
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    <h2 class="mb-4 fs-5 custom-font-bold">7.Marketing and Communication</h2>
      <p class="fs-6 fclr">
      a. Ticketistan.com may use organizer information for marketing and promotional purposes.
      </p>
      <p className="fs-6 fclr">
      b. Organizers and customer scan opt-out of marketing communications.
      </p>  
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    <h2 className="mb-4 fs-5 custom-font-bold">8.Legal Compliance</h2>
      <p className="fs-6 fclr">
      a. Ticketistan.com complies with relevant data protection laws and may disclose information when required by law.
      </p> 
    </div>
  </div>

  <div className="row">
    <div className="col-12 p-0">
    <h2 className="mb-4 fs-5 custom-font-bold">9.Changes to Privacy Policy</h2>
      <p className="fs-6 fclr">
      a. Ticketistan.com reserves the right to update this Privacy Policy.
      </p>
      <p class="fs-6 fclr">
      b. Users will be notified of significant changes, and continued use of the services implies acceptance.
      </p>  
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    <h2 className="mb-4 fs-5 custom-font-bold">10. Contact Information</h2>
      <p className="fs-6 fclr">
      a. For privacy-related inquiries or concerns, please contact us at [privacy@email.com].
      </p>
      <p className="fs-6 fclr">
      By using Ticketistan.com's ticketing services, event organizers andcustomers agree to the terms outlined in this Privacy Policy. It is recommendedto periodically review this policy for updates. Last updated: [date].
      </p>  
    </div>
  </div>

  
</div>

    </div>
    </section>
    </>
    
  );
};

export default PrivacyPolicy;
