import React, { useState } from 'react';
import axios from 'axios';
import '../Style/LoginForm.css';
import { useDispatch } from 'react-redux';
import { LOGIN } from '../store/auth/authSlice';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const dispatch  = useDispatch()
  const [loading , setLoading] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/accounts/login/`, formData);
      console.log('Login successful!', response.data);
      dispatch(LOGIN(response?.data))

      // Save user authentication token to local storage or session storage
      // localStorage.setItem('token', response.data.token);

      // Optionally, reset the form after successful submission
      setFormData({
        email: '',
        password: ''
      });
    } catch (error) {
      console.error('Error logging in:', error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="login-form-container mt-5 mb-5">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="password" 
          name="password" 
          placeholder="Password" 
          value={formData.password} 
          onChange={handleChange} 
          required 
        />
        <button type={loading ? "" : "submit"}>{loading ? "Loading..." : "Sign In"}</button>
      </form>
      <p className='pt-2'>Don't have an account? <Link to="/signup">Sign Up</Link></p>
    </div>
  );
};

export default LoginForm;
