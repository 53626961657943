import React, { useEffect, useState } from 'react';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const FinalizeBooking = () => {
    const [error, setError] = useState(null);
    const [bookingData, setBookingData] = useState(null);
    const access_token = localStorage.getItem('AccessToken');
    const item_id = localStorage.getItem('item_id');
    const booking_id = localStorage.getItem('booking_id');
    const rc = localStorage.getItem('RC');
    const rd = localStorage.getItem('RD');
    const ts = localStorage.getItem('TS');
    const o = localStorage.getItem('orderId');
    useEffect(() => {
        const finalizePaymentBooking = async () => {


            if (!access_token) {
                setError('AccessToken header is missing');
                return;
            }

            const url = `${BASE_URL}/flights/items/${item_id}/book/${booking_id}`;
            const headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                AccessToken: access_token,
                Version: 'v1',
            };

            try {
                const response = await axios.put(url, { rc, rd, ts, o }, { headers });
                if (response.status === 200) {
                    setBookingData(response.data);
                } else {
                    setError(response.data);
                }
            } catch (error) {
                setError('Booking flight request failed');
            }
        };

        finalizePaymentBooking();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (bookingData) {
        return <div>Booking Data: {JSON.stringify(bookingData)}
          <button onClick={() => { window.location.href = '/e-ticket'; }}>Next Step</button></div>;
      
    }

    return <div>Loading...</div>;
};

export default FinalizeBooking;