import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../flights/FlightSearchForm.css'
import citySvg from '../assets/svg/city.svg'
import DatePickerIcon from "../assets/svg/Datepicker.svg"

function FlightSearchForm() {
  const [flightType, setFlightType] = useState('oneWay');
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("adults");
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [departureData,setDepartureDate]=useState(getCurrentDate)
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  

  function getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };
  const handleDepartureDateChange = (e) => {
    setDepartureDate(e.target.value);
  };

  
  
  
  
  const decreaseCount = (type,e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (type) {
      case 'adult':
        if (adultCount > 1) setAdultCount(adultCount - 1);
        break;
      case 'child':
        if (childCount > 0) setChildCount(childCount - 1);
        break;
      case 'infant':
        if (infantCount > 0) setInfantCount(infantCount - 1);
        break;
      default:
        break;
    }
  };

    const increaseCount = (type,e) => {
      e.preventDefault();
      e.stopPropagation();
      switch (type) {
        case 'adult':
          if (adultCount < 3) setAdultCount(adultCount + 1);
          break;
        case 'child':
          if (childCount < 3) setChildCount(childCount + 1);
          break;
        case 'infant':
          if (infantCount < 3) setInfantCount(infantCount + 1);
          break;
        default:
          break;
      }
    };

  const handleFlightTypeChange = (e) => {
    setFlightType(e.target.value);
    setSelectedOption(e.target.value);
  };

  const getPassengerCount = () => {
    switch (selectedOption) {
      case 'adults':
        return adultCount;
      case 'children':
        return childCount;
      case 'infants':
        return infantCount;
      default:
        return 0;
    }
  };
  const totalPersons = adultCount + childCount + infantCount;

  return (
    <section className="bg-dark search-form-section pt-lg-5 pb-lg-5">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
    <div className="search-form">
      <p className="h2 text-white font-weight-600 mb-3">Search for Hotels</p>
      <p className="lead h5 text-white font-weight-400">Find the best and most affordable hotel rooms across Pakistan </p>
      <form id="flightSearch" className="mt-4">
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                <span className="input-group-text">
                <img src={citySvg} alt="" />
                </span>
                  <input
                    name="city"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="city"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={checkinDate}
          onChange={(date) => setCheckinDate(date)}
          placeholderText="checkin"
          className="form-control arrival-icon"
          required
        />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <span className="input-group-text">
                <img src={DatePickerIcon} alt="" />
                </span>
                <DatePicker
          selected={checkoutDate}
          onChange={(date) => setCheckoutDate(date)}
          placeholderText="checkout"
          className="form-control arrival-icon"
          required
        />
                </div>
              </div>
            </div>
        <div className="row gx-2 gy-3">

        <div className="col-lg-5 col-md-6">
            <select className="form-control" >
              <option value="">Rooms</option>
              <option value="one">1</option>
              <option value="two">2</option>
              <option value="three">3</option>
              <option value="four">4</option>

            </select>
          </div>


          <div className="col-lg-5 col-md-6">
            <div className="dropdown">
              <input
                type="text"
                className="form-control"
                placeholder="No of Passengers"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={`Total Persons: ${totalPersons}`}
              />
              {/* <select name="select" id="" className="form-control">
                <option value="option-one">Rooms</option>
                <option value="option-two">1</option>
                <option value="option-three">2</option>
                <option value="option-three">3</option>
                <option value="option-three">4</option>
              </select> */}
              <div className="dropdown-menu p-3 w-100" id="passengers">
                {/* Passengers dropdown */}
                <div className="row g-3 align-items-center">
    {/* Adults */}
    <div className="col-4">
        <label htmlFor="adult" className="mb-0">Adult(s)</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => decreaseCount('adult', e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center w-25" id="adultCount" value={adultCount} readOnly />
        <button type="button" className="btn btn-outline-secondary " onClick={(e) => increaseCount('adult', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Children */}
    <div className="col-4">
        <label htmlFor="child" className="mb-0">Children</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" disabled={childCount === 0} onClick={(e) => decreaseCount('child', e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center  w-25" id="childCount" max="3" value={childCount} readOnly />
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => increaseCount('child', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Infants */}
    <div className="col-4">
        <label htmlFor="infant" className="mb-0">Infant(s)</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" disabled={infantCount === 0} onClick={(e) => decreaseCount('infant',e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center  w-25" id="infantCount" max="3" value={infantCount} readOnly />
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => increaseCount('infant', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Done Button */}
    <div className="col-12">
        <button className="btn btn-primary w-100 btn-clr">Done</button>
    </div>
</div>

    
                {/* ... (Keep the same as in your original code) */}
              </div>
            </div>
          </div>
          
          <div className="col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary text-white w-100 btn-clr">
            <i className="bi bi-search pe-2"></i>
              Search
            </button>
          </div>
        </div>
      </form>
    </div>

    </div>
      </section>
  );
}

export default FlightSearchForm;
