import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, ProgressBar, Spinner } from "react-bootstrap";
import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";

const HandshakeForm = ({ onHandshakeSuccess }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const handleSubmit = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/handshake/`, {});
                if (response.data.auth_token) {
                    onHandshakeSuccess(response.data);
                } else {
                    setError("Handshake failed");
                }
            } catch (error) {
                setError("Error: " + error.message);
            } finally {
                setLoading(false); // Set loading to false when API call is done
            }
        };

        handleSubmit();
    }, [onHandshakeSuccess]);

    return (
        <div>
            <Container className="mt-4">
                <Card className="border">
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Row className="d-flex justify-content-between align-items-center">
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaPlaneDeparture size={32} color="#3B76B0" />
                                            <div>Booking</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaCreditCard size={32} color="#6c757d" />
                                            <div>Payment</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} className="text-center">
                                        <div>
                                            <FaTicketAlt size={32} color="#6c757d" />
                                            <div>E-ticket</div>
                                        </div>
                                    </Col>
                                </Row>
                                <ProgressBar className="mt-3">
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={33} className="bg-info" />
                                    <ProgressBar now={0} className="bg-light" />
                                </ProgressBar>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            {loading && <div className="d-flex justify-content-center align-items-center pt-5 pb-5"><Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>}
            {error && <p>{error}</p>}
        </div>
    );
};

export default HandshakeForm;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   ProgressBar,
//   Spinner
// } from "react-bootstrap";
// import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";

// const HandshakeForm = ({ onHandshakeSuccess }) => {
//   const [loading, setLoading] = useState(false); // State to track loading
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     handleSubmit();
//   }, []);

//   const handleSubmit = async () => {
//     setLoading(true); // Set loading to true when the request starts
//     setError(null);

//     try {
//       const response = await axios.post('https://k480x10x-8000.inc1.devtunnels.ms/payments/handshake/', {});

//       if (response.data.auth_token) {
//         onHandshakeSuccess(response.data);
//       } else {
//         setError("Handshake failed");
//       }
//     } catch (error) {
//       setError("Error: " + error.message);
//     } finally {
//       setLoading(false); // Set loading to false when the request is done
//     }
//   };

//   return (
//     <div>
//       <Container className="mt-4 sm:mt-4 md:mt-8 lg:mt-8 xl:mt-8 xxl:mt-8">
//         <Card className="border">
//           <Card.Body>
//             <Row>
//               <Col xs={12}>
//                 <Row className="d-flex justify-content-between align-items-center">
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaPlaneDeparture size={32} color="#3B76B0" />
//                       <div>Booking</div>
//                     </div>
//                   </Col>
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaCreditCard size={32} color="#6c757d" />
//                       <div>Payment</div>
//                     </div>
//                   </Col>
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaTicketAlt size={32} color="#6c757d" />
//                       <div>E-ticket</div>
//                     </div>
//                   </Col>
//                 </Row>
//                 <ProgressBar className="mt-3">
//                   <ProgressBar now={33} className="bg-info" />
//                   <ProgressBar now={33} className="bg-info" />
//                   <ProgressBar now={0} className="bg-light" />
//                 </ProgressBar>
//               </Col>
//             </Row>
//             {loading && (
//               <Row className="d-flex justify-content-center mt-4">
//                 <Spinner animation="border" role="status">
//                   <span className="sr-only">Loading...</span>
//                 </Spinner>
//               </Row>
//             )}
//           </Card.Body>
//         </Card>
//       </Container>

//       {error && <p className="text-danger text-center mt-3">{error}</p>}
//     </div>
//   );
// };

// export default HandshakeForm;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   ProgressBar,
// } from "react-bootstrap";
// import { FaPlaneDeparture, FaCreditCard, FaTicketAlt } from "react-icons/fa";


// const HandshakeForm = ({ onHandshakeSuccess }) => {
//    const [error, setError] = useState(null);

//     // useEffect(() => {
//     //   handleSubmit()
//     // } , [])

//     useEffect(() => {
//       handleSubmit()
//     }, [])
    

//     const handleSubmit = async () => {
//         // e.preventDefault();

//        try {
//             const response = await axios.post('https://k480x10x-8000.inc1.devtunnels.ms/payments/handshake/', {});

//             if (response.data.auth_token) {
//                 onHandshakeSuccess(response.data);
//             } else {
//                 setError("Handshake failed");
//             }
//         } catch (error) {
//             setError("Error: " + error.message);
//         }
//     };

//     return (
//         <div>
//             <Container className="mt-4 sm:mt-4 md:mt-8 lg:mt-8 xl:mt-8 xxl:mt-8">
//         <Card className="border">
//           <Card.Body>
//             <Row>
//               <Col xs={12}>
//                 <Row className="d-flex justify-content-between align-items-center">
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaPlaneDeparture size={32} color="#3B76B0" />
//                       <div>Booking</div>
//                     </div>
//                   </Col>
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaCreditCard size={32} color="#6c757d" />
//                       <div>Payment</div>
//                     </div>
//                   </Col>
//                   <Col xs={4} className="text-center">
//                     <div>
//                       <FaTicketAlt size={32} color="#6c757d" />
//                       <div>E-ticket</div>
//                     </div>
//                   </Col>
//                 </Row>
//                 <ProgressBar className="mt-3">
//                   <ProgressBar now={33} className="bg-info" />
//                   <ProgressBar now={33} className="bg-info" />
//                   <ProgressBar now={0} className="bg-light" />
//                 </ProgressBar>
//               </Col>
//             </Row>
//           </Card.Body>
//         </Card>
//             </Container>

//             {/* <Container className='pb-5 pt-5'>
//                 <h2>Handshake Request</h2>
//             <form onSubmit={handleSubmit}>
//                 <button type="submit">Submit</button>
//             </form>
//             {error && <p>{error}</p>}
//             </Container> */}
//         </div>
//     );
// };

// export default HandshakeForm;
