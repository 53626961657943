import React, { useState } from "react";
import "./FlightCard.css";
import "./FlightOffersSection.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const FlightCard = ({ flightOffer, inReviewPage }) => {
  const location = useLocation();
  const [showStops, setShowStops] = useState(false);
  const { flightId } = useParams();
  const [flightCardDetails, setFlightCardDetails] = useState(false);

  if (!flightOffer) {
    return null;
  }

  const {
    price: { currency, total },
    segments,
  } = flightOffer;

  const totalStops = segments.length - 1;

  const handleToggleStops = () => {
    setShowStops(!showStops);
  };

  const renderStopDetails = () => {
    return segments.map((segment, index) => {
      if (index === 0) return null; // Skip the first segment as it's the departure
      const {
        origin: {
          name: originName,
          code: originCode,
          date: originDate,
          time: originTime,
        },
        destination: {
          name: destinationName,
          code: destinationCode,
          date: destinationDate,
          time: destinationTime,
        },
      } = segment;

      return (
        <div key={index} className="stopDetails">
          <p>
            Stop {index}: {originName} ({originCode}) to {destinationName} (
            {destinationCode})
          </p>
          <p>
            Departure: {originDate} {originTime}
          </p>
          <p>
            Arrival: {destinationDate} {destinationTime}
          </p>
        </div>
      );
    });
  };

  const {
    carrier: { name: carrierName, flightNumber },
    origin: {
      name: originName,
      code: originCode,
      date: originDate,
      time: originTime,
    },
    // destination: { name: destinationName, code: destinationCode, date: destinationDate, time: destinationTime, terminal },
    // baggageAllowance: { amount: baggageAmount, unitName: baggageUnit }, // TODO::have to unComment
    baggageAllowance: {
      amount: baggageAmount = 0,
      unitName: baggageUnit = "",
    } = {},
    class: { name: className, availableSeats: seats },
  } = segments[0]; // Assuming you display details for the first segment

  const destinationName = segments[segments.length - 1]?.destination?.name;
  const destinationCode = segments[segments.length - 1]?.destination?.code;
  const destinationDate = segments[segments.length - 1]?.destination?.date;
  const destinationTime = segments[segments.length - 1]?.destination?.time;
  const terminal = segments[segments.length - 1]?.destination?.terminal;

  const img =
    "https://1000logos.net/wp-content/uploads/2023/05/Airblue-Logo-thumb.png";

  return (
    <>
      <section className="my-5 mt-0 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5">
          <div className="flightCard">
            {/* CARD STARTS HERE */}
            <div
              className="cards"
              style={{
                borderBottomLeftRadius: showStops ? 0 : "",
                borderBottomRightRadius: showStops ? 0 : "",
              }}
            >
              {/* img section */}
              <div className="flightLogo">
                <img
                  src={img}
                  style={{ maxWidth: "80%", maxHeight: "70px" }}
                  alt="Airline Logo"
                />
                <div className="flightName">{carrierName}</div>
                <div className="flightNumber">{flightNumber}</div>
              </div>
              {/* mid section */}
              <div className="midSection">
                <div className="originTime">
                  {originTime} <span className="totalTime">7h 5m</span>{" "}
                  {destinationTime}{" "}
                  <span
                    style={{
                      marginTop: "-25px",
                      color: "#00A7E1",
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    + 1D
                  </span>
                </div>
                <div className="originName">
                  {originName}&nbsp;- {originCode}
                  <span
                    style={{
                      color: "#00A7E1",
                      borderBottom: "1px solid #00A7E1",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={handleToggleStops}
                  >
                    {totalStops} Stop{totalStops > 1 ? "s" : ""}
                  </span>{" "}
                  -&nbsp;&nbsp;&nbsp; {destinationName}&nbsp;- {destinationCode}{" "}
                  {terminal && "Terminal " + terminal}
                </div>
                <div
                  style={{
                    color: "#6D6D6D",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                >
                  Baggage Allowance : {baggageAmount} {baggageUnit}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{seats} &nbsp;seats Available&nbsp;&nbsp;{className}
                </div>
                {/* {showStops && renderStopDetails()} */}
              </div>
              {/* last section */}
              <div className="flightCardLastSection">
                <Link
                  to={
                    inReviewPage
                      ? ""
                      : flightId
                      ? "passenger-details"
                      : `/flight/${flightOffer?.itemId}`
                  }
                  className="flightCardButton"
                  onClick={() => {
                    if (inReviewPage) {
                      setFlightCardDetails(!flightCardDetails);
                    }
                  }}
                >
                  {currency} {total.toFixed(2)}
                </Link>
              </div>
            </div>
          </div>
          {flightCardDetails && (<div>

            <div>
              {flightOffer?.price.currency}
              </div>
          </div>)}
          {/* STOP CARDS */}
          {showStops && (
            <>
              {segments?.map((segment, index) => (
                <div
                  style={{
                    border: "1px solid #C8C8C8",
                    borderTop: "none",
                    padding: "5px 10px",
                    width: "100%",
                    borderBottomLeftRadius:
                      index === segments?.length - 1 ? "12px" : "",
                    borderBottomRightRadius:
                      index === segments?.length - 1 ? "12px" : "",
                  }}
                >
                  {/* line 1 */}
                  <div
                    style={{
                      width: "fit-content",
                      backgroundColor: "#cce3f7",
                      border: "1px solid #00A7E1",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    {segment.origin.date}
                  </div>
                  {/* line 2 */}
                  <div style={{ display: "flex", width: "75%" }}>
                    {/* colums */}
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>
                        <img src="" style={{ maxWidth: "100%" }} alt="pia" />
                      </div>
                      <div>{segment.carrier.code}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.origin.time}</div>
                      <div>{segment.origin.name}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.destination.time}</div>
                      <div>{segment.destination.name}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.carrier.name}</div>
                      <div>{segment.carrier.flightNumber}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>Class</div>
                      <div>{segment.class.name}</div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default FlightCard;

const stopColumnStyle = {
  width: "20%",
  textAlign: "center",
};
