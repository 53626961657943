import React from 'react'

const MobileApp = () => {
    
  return (
    <>
    <section id="mobile-app" className="mobile-app-section ">
        <div className="container px-0 px-md-4 px-lg-5">
          <div className="mobile-app px-3 px-lg-5 rounded-sm rounded-1">
            <div className="row">
              <div className="col-lg-7 pt-5 pt-lg-0 align-self-center">
                <p className="h2 fs-3 custom-font-bold  mb-2 pt-lg-5">
                  Download our app
                </p>
                <p className="lead h5 custom-font-normal">
                  Get amazing deals and bundles on Ticketistan Application{" "}
                </p>
                <div className="d-flex mt-3 mb-3">
                  <div className="me-1">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/ios-app-light.bac60b30.svg"
                        alt="Download IOS App"
                      />
                    </a>
                  </div>
                  <div className="ms-1">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/android-app-light.031b1575.svg"
                        alt="Download Android App"
                      />
                    </a>
                  </div>
                </div>
                <form>
                  <div className="row g-2">
                    <div className="col-md-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter your phone number"
                        required
                        minLength="11"
                        maxLength="11"
                      />
                    </div>
                    <div className="col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary-light btn-clr shadow"
                      >
                        Get Download Link
                      </button>
                    </div>
                    <div className="col-12">
                      <p className="mb-0 font-weight-400 pb-5">
                        {" "}
                        Your privacy is important for us. Check our{" "}
                        <a
                          href="/"
                          className="link-dark text-decoration-underline"
                        >
                          privacy policy
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-lg-5 pt-5 d-flex align-self-end justify-content-center justify-content-lg-end">
              <img className="img-fluid" src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/bookme-app.ea7603cd.svg" alt="Download Bookme App" />
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MobileApp