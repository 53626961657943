/* eslint-disable */
import "./App.css";
import Header from "./Components/layout/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Flight from "./Pages/Flight.jsx";
import Bus from "./Pages/Bus.jsx";
import Trains from "./Pages/Trains.jsx";
import Movies from "./Pages/Movies.jsx";
import CarRental from "./Pages/CarRental.jsx";
import Events from "./Pages/Events.jsx";
import Tours from "./Pages/Tours.jsx";
import Sidebar from "./Components/layout/Sidebar.js";
import SignUpForm from "./Pages/SignUpForm.jsx";
import LoginForm from "./Pages/LoginForm.jsx";
import ContactPage from "./Pages/ContactPage.js";
import Footer from "./Components/layout/Footer.jsx";
import Hotel from "./Pages/Hotel.jsx";
import AboutUs from "./Pages/AbouUS.jsx";
import Announcements from "./Pages/Announcements.jsx";
import TermsandConditions from "./Pages/TermsandConditions.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import img from "../src/assets/imgs/image.jpeg";
import Home from "./Pages/Home.jsx"
import FrequentlyAskQuestion from "./Pages/FrequentlyAskQuestion.jsx";
import FlightReviewPage from "./Pages/FlightReviewPage/FlightReviewPage.jsx";
import BookingForm from "./Components/flights/BookingForm.jsx";
import PassengerDetailsPage from "./Pages/passengerDetailsPage/PassengerDetailsPage.jsx";
import ProtectedRoute from "./Components/protectedRoute/ProtectedRoute.jsx";
import AuthRoute from "./Components/authRoute/AuthRoute.jsx";
import PaymentPage from "./Components/payments/PaymentPage.jsx";
import InvoicePage from "./Components/invoicePage/InvoicePage";
import PaymentConfirm from "./Pages/paymentPage/PaymentConfirm.jsx";
import TransactionSuccessful from "./Components/payments/TransactionSuccessful.jsx";
import TransactionFailed from "./Components/payments/TransactionFailed.jsx";
import Eticket from "./Components/eticket/Eticket.jsx";
import FinalizeBooking from "./Components/flights/FinalizeBooking/FinalizeBooking.jsx";
const App = () => {
  const [isOpen, setOpen] = useState(true);

  // Function to toggle the sidebar
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <BrowserRouter>
      <Header isOpen={isOpen} toggle={toggle} />
        <Sidebar isOpen={isOpen}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/:body" element={<Home copy={true} />} /> */}
            <Route path="/flight" element={<Flight />} />
            <Route path="/flight/:flightId" element={<FlightReviewPage />} />
            {/* <Route path="/flight/:flightId/passenger-details" element ={<ProtectedRoute  children={<PassengerDetailsPage />} />} /> */}
            <Route path="/flight/:flightId/passenger-details" element ={<PassengerDetailsPage />} />
            <Route path="/hotel" element={<Hotel/>} />
            <Route path="/bus" element={<Bus />} />
            <Route path="/trains" element={<Trains />} />
            <Route path="/movies" element={<Movies />} />
            <Route path="/carRental" element={<CarRental />} />
            <Route path="/events" element={<Events />} />
            <Route path="/tours" element={<Tours />} />
            <Route path="/signUp" element={<SignUpForm />} />
            <Route path="/login" element ={<AuthRoute  children={<LoginForm />} />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/terms" element={<TermsandConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FrequentlyAskQuestion/>} />
            <Route path="/booking" element={<BookingForm/>}/>
            <Route path="/payment" element={<PaymentPage/>}/>
            <Route path="/invoice" element={<InvoicePage/>}/>
            {/* <Route path="/payment-confirm" element={<PaymentConfirm />} /> */}
            <Route path="/payment-confirm" element={<PaymentPage />} />
            <Route path="/e-ticket" element={<Eticket />} />
            <Route path="/transactionSuccessful" element={<TransactionSuccessful />} />
            <Route path="/transactionFailed" element={<TransactionFailed />} />
            <Route path="/finalizebooking" element={<FinalizeBooking />} />
          </Routes>
          <Footer />
        </Sidebar>
      </BrowserRouter>
     
    </>
  );
};

export default App;
